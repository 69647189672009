export default {
  fee_labels: {
    IPO: "Obtain documents from IPO",
    GRO: "Obtain documents from GRO",
    companies_house: "Obtain documents from Companies House",
    translation: "Arrange for translation of document(s)",
    printing: "Arrange printing",
    copying: "Arrange copying",
    binding: "Arrange binding",
    id_verification: 'ID Verification',
  },

  ancillaryFeesAccountingCodes: {
    IPO: "824",
    GRO: "495",
    companies_house: "824",
    translation: "488",
    delivery: "529",
    printing: "529",
    copying: "529",
    binding: "529",
    id_verification: "488",
  },

  fees_per_document: {
    IPO: true,
    GRO: true,
    companies_house: true,
    translation: true,
    delivery: false,
    printing: true,
    copying: true,
    binding: true,
    id_verification: false
  },

  feeSections: {
    notaryfees: "Notary fees",
    defaultfees: "Legalisation Fees",
    ancillaryfees: "Ancillary Fees",
    deliveryfees: "Delivery Fees",
    expenses: "Other Expenses",
  },

  fee_vat_status: {
    IPO: false,
    GRO: false,
    companies_house: false,
    translation: true,
    delivery: true,
    printing: true,
    copying: true,
    binding: true,
    id_verification: true
  },
  paymentInformation: "Our fees are payable on invoice (14 days after invoice date payment terms, unless we agree otherwise in writing). We usually invoice at the appointment or once the notarial appointment/work has taken place. Where possible we ask to receive payment by bank transfer at the appointment. We reserve the right to ask for payment on account (particularly for translation, embassy and legalisation costs) before an appointment or any notarial work is performed. In this case any balancing fee after payment on account will be invoiced after the notarial and/or legalisation work has been completed and our terms apply.",
  
  options: {
    moneyConfig: {
      // The character used to show the decimal place.
      decimal: ".",
      // The character used to separate numbers in groups of three.
      thousands: ",",
      // The currency name or symbol followed by a space.
      prefix: "£ ",
      // The suffix (If a suffix is used by the target currency.)
      suffix: "",
      // Level of decimal precision. REQUIRED
      precision: 2,
      // If mask is false, outputs the number to the model. Otherwise outputs the masked string.
      masked: false,
    },

    fee_types: [
      {
        label: "Requires payment prior to appointment",
        value: 1,
        description:
          "Your payment on account of our service fees and expenses is requested please prior to appointment. Any balance remaining is to be paid in accordance with our terms of business and invoice.",
      },
      {
        label: "Requires payment at appointment",
        value: 2,
        description:
          "We will take payment from you at the appointment, either by electronic payment to our bank account or by credit/debit card.  Please note that AMEX is not supported.  Thank you.",
      },
      {
        label: "Requires payment on invoice (payment terms apply)",
        value: 3,
        description:
          "Your payment of our service fees and expenses is requested please on receipt of invoice (payment terms apply).",
      },
    ],
    invoice_target: [
      { label: "Principal / Individual", value: 1 },
      { label: "Principal / Individual c/o Admin Contact", value: 2 },
      { label: "Representative", value: 3 },
      { label: "Organisation", value: 4 },
      { label: "Other", value: 5 },
    ],
  },
};
