import to from "await-to-js";
import { lcfirst, ucfirst } from "locutus/php/strings";
import { empty } from "locutus/php/var";
import _ from "lodash";
import { ENQUIRY_WHO } from "src/store/constants";
import xeroApiService from "src/service/xeroApiService";

import {
  getAssociationTargetTable,
  getRelationshipNameFromAssociation,
  notify,
  relationshipsAreEqual,
  strip,
} from "src/utils";

/**
 * Does not alter the map.
 * Just allows a namespace to be provided.
 * If no namespace is provide, then there must either be none required, or it must be included in the map
 * @returns
 */
export const normalisedNamespace = (namespace, map) => {
  if (typeof namespace !== "string") {
    map = namespace;
    namespace = "";
  } else if (namespace.charAt(namespace.length - 1) !== "/") {
    namespace += "/";
  }
  return { namespace, map };
};

/**
 * @from 'vuex' private function
 * Return a function expect two param contains namespace and map. it will normalize the namespace and then the param's function will handle the new namespace and the map.
 * @param {Function} fn
 * @return {Function}
 */
export function normalizeNamespace(fn) {
  return function (a, b, from) {
    const { namespace, map } = normalisedNamespace(a, b);
    return fn(namespace, map, from);
  };
}

/**
 * @from 'vuex' private function
 * Normalize the map
 * normalizeMap('',[1, 2, 3]) => [ { key: 1, val: 1 }, { key: 2, val: 2 }, { key: 3, val: 3 } ]
 * normalizeMap('',{a: 1, b: 2, c: 3}) => [ { key: 'a', val: 1 }, { key: 'b', val: 2 }, { key: 'c', val: 3 } ]
 * @param {string} namespace
 * @param {Array|Object} map
 * @return {Object}
 */
export function normalizeMap(namespace, map) {
  if (!isValidMap(map)) {
    return [];
  }
  return Array.isArray(map)
    ? map.map(function (key) {
      return {
        key: `${namespace}${key}`,
        val: namespace ? `${namespace}${key}` : key,
      };
    })
    : Object.keys(map).map(function (key) {
      return {
        key: key,
        val: namespace ? `${namespace}${map[key]}` : map[key],
      };
    });
}

/**
 * @from 'vuex' private function
 * Validate whether given map is valid or not
 * @param {*} map
 * @return {Boolean}
 */
export function isValidMap(map) {
  return _.isArray(map) || _.isObject(map);
}

class ServiceLayer {
  constructor(store, router, $API) {
    this.$store = store;
    this.actions = {};
    this.$API = $API;
    this.$api = this.$API.wrap;
    this.$router = router;
    this.query = this.$router.history.current.query;
    this.xero = { ...xeroApiService };
  }

  /**
   * Registers a single action and dispatches it immediately.
   * i.e. Ensures that an action is registered before calling it.
   * @param {string} storeName
   * @param {string} actionName
   * @param {object} args
   */
  dammit(storeName, actionName, ...args) {
    return this.actions[this.registerAction(storeName, actionName)](...args);
  }

  /**
   * Convenience function to reduce boilerplate; handles error notifications to the user
   * @param {string} action
   * @param  {any} params
   */
  async toto(action, params) {
    // console.log("params", params);

    let [errA, resA] = await to(this.dispatch(action, params));
    if (errA) {
      console.warn(e);
      // console.log("params", params);
      notify("ServiceLayer (toto): " + e, "negative");
    }
    if (resA)
      // console.log("ExecutionChain SaverMixin response: ", resA);
      return [errA, resA];
  }

  /**
   * Same as toto but will also first register the action
   * @param {string} storeName
   * @param {string} action
   * @param  {any} params
   * @returns {promise}
   */
  async totoDammit(storeName, action, params) {
    this.registerAction(storeName, action);
    // console.log("params", params);
    await this.toto(`${storeName}/${action}`, params);
  }

  async usersettingsDammit() {
    const usersettings = this.$store.getters["usersettings/all"];
    if (!usersettings.length) {
      const actingAs = this.$store.getters["shared/actingAs"];
      await this.totoDammit("people", "loadById", {
        id: actingAs.userId,
        options: {
          include: ["usersettings"],
        },
      });
      const personObject = this.$store.getters["people/byId"]({
        id: actingAs.userId,
      });
      await this.relatedDammit("usersettings", personObject);
    }
  }

  /**
   * Dispatches a registered action, passing arguments through
   * @param {string} key
   * @param {mixed} val
   */
  dispatch() {
    // console.log("arguments", arguments);
    for (
      var _len = arguments.length, args = new Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }
    // console.log("args", args);
    const key = args.splice(0, 1);
    // console.log("key", key);
    if (typeof this.actions[key] !== "function") {
      throw new Error(`Action "${key}" is not registered`);
    }
    // console.log("args", args);
    return this.actions[key](...args);
  }

  /**
   * Loads the entity data from the store record into the component's vuex-mapped model
   * @todo: currently this makes certain assumptions about the vuex-mappings, which are not necessarily correct
   * E.g. that each association from the schema has been mapped and should be written to.
   * @param {string | object} schemaName entity object or schema string; this allows a single
   * argument if the entity has the correct type property and we're sure we have an entity
   * @param {string | object} entityDammit entity object or id string; this allows this function to be run independently
   * @param {array} associations objects
   * @param {string} formDataPath i.e. destination in store for the copy
   * @param {boolean} copyId true: copy the source id to the destination, false: generate a new id
   */
  async entityToForm(
    sourceSchema,
    entityDammit,
    associations,
    formDataPath,
    copyId = true
  ) {
    if (_.isObject(sourceSchema)) {
      entityDammit = _.cloneDeep(sourceSchema);
      sourceSchema = entityDammit.type;
    }

    //- ensure we have entity object before proceeding
    const sourceEntity = await this.$store.dispatch(
      "submissions/getReststateEntity",
      {
        storeName: sourceSchema,
        entityDammit,
      }
    );

    //- get empty data structure for this.schema
    const [err, recipient] = await to(
      this.$store.dispatch("schemas/getEmptyRow", {
        schema: sourceSchema,
        insert: false,
      })
    );

    if (err) {
      throw err;
    }

    //- load the attributes and relationships to the form
    const options = {
      source: sourceEntity,
      associations,
      path: formDataPath,
      recipient,
      copyId,
      // from: "serviceLayer"
    };

    await this.$store.dispatch("submissions/entityToForm", options);
  }
  /**
   * Load related records for provided associations.
   * These mappings are used rather than direct dispatch methods,
   * so that they can be wrapped by the api for queuing etc.
   * @param {object} entity the entity for which associations will be found and processed
   * @param {array} limitTo if a list of association names is provide, then only these will be processed
   */
  async loadRelationships(entity, limitTo = null, from = "loadRelationships") {
    // console.log(">>>>>>>>>>>>>>>> serviceLayer.loadRelationships START");
    // console.log("serviceLayer.loadRelationships limitTo", limitTo);
    //- iterate over associations from the schema and create store aliases
    const associations = this.$store.getters["schemas/getEntityAssociations"](
      entity,
      "RelationshipsMixin loadRelationships" + from
    );
    if (!empty(associations)) {
      for (let association of associations) {
        // console.log(
        //   "serviceLayer.loadRelationships association.name",
        //   association.name
        // );

        if (limitTo === null || limitTo.includes(association.name)) {
          let relationshipName = "";
          if (_.isObject(association)) {
            relationshipName = getRelationshipNameFromAssociation(association);
          } else {
            relationshipName =
              this.$store.getters["schemas/relationshipNameFromAssociation"](
                association
              );
          }
          // console.log("relationshipName", relationshipName);
          const searchOptions = {
            parent: { id: entity.id, type: entity.type },
            relationship: relationshipName,
          };

          // console.log(
          //   "serviceLayer.loadRelationships relationshipName",
          //   relationshipName
          // );
          // console.log(
          //   "serviceLayer.loadRelationships searchOptions",
          //   searchOptions
          // );

          await this.dammit(
            lcfirst(association.name),
            "loadRelated",
            searchOptions
          );
        }
      }
    }
  }
  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async reststateEntity(entity) {
    return await this.$store.dispatch("submissions/getReststateEntity", {
      storeName: entity.type,
      entityDammit: entity,
    });
  }

  /**
   * This prepares an object containing new mappings to be registered.
   * @param {array} newActions list of normalised action definitions
   * @returns {object} with action names as keys and functions as values
   */
  generateArrayOfMappedActions(newActions) {
    const res = {};
    newActions.forEach((val, key) => {
      const actionKey = val.key,
        actionName = val.val,
        pathSplit = actionName.split("/"),
        action = pathSplit[1],
        storeName = pathSplit[0];

      res[actionKey] = this.getWrappedMappedAction(
        action,
        storeName,
        actionKey
      );
    });
    return res;
  }

  /**
   * @param {object} data message data
   * @param {object} metadata message description
   * @returns {object} wrapped reststateAction
   */
  getWrappedMappedAction(action, storeName, key) {
    const api = this.$api;
    const self = this;
    var mappedAction = (function () {
      return function mappedAction(data, metadata) {
        const RS = self.$API.RS;
        const requestMethod = RS.getRequestMethod(key);
        const api = self.$api;
        const options = _.merge(
          {
            actionPath: key,
            storeName,
            action,
            requestMethod,
            data,
          },
          metadata
        );
        return api.apply(self, ["RS", options]);
      };
    })(self);
    return mappedAction;
  }

  /**
   * @param {array} normalisedMap normalised map of action definitions submitted for registration
   * @returns {array} normalised map of new action definitions
   */
  getNewActions(normalisedMap) {
    let existingKeys = Object.keys(this.actions);
    return normalisedMap.filter((x) => !existingKeys.includes(x.key));
  }

  getAssociationTargetTable(association) {
    return getAssociationTargetTable(association);
  }

  getAssociationTargetAction(association, action) {
    const target = this.getAssociationTargetTable(association);
    return `${target}/${action}`;
  }

  isCachedRecord(schema, id) {
    return !empty(this.$store.getters[`${schema}/byId`](id));
  }

  /**
   * Given an association and an array of related objects to be added
   * This will create POST for any of those that need insertion before relating
   * @param {object} association
   * @param {array} added of reststate objects
   */
  async insertNewRelationships(association, added) {
    if (empty(added)) return;
    const target = this.getAssociationTargetTable(association);
    if (empty(target)) {
      throw new Error("error: target undefined");
    }
    const toInsert = added.filter((x) => {
      //- check if related record exists in cache, if so, don't include it, otherwise do
      if (!this.isCachedRecord(target, x.id)) {
        // console.log("record is cached", x.id);
        return true;
      }
    });
    if (empty(toInsert)) return;
    //- use for loop to respect promises
    let err,
      errs = [];
    for (let relationship of toInsert) {
      if (!relationship.id) continue;
      const link = { id: relationship.id, type: relationship.type };
      // console.log(link);
      await this.totoDammit(target, "create", relationship);
    }
  }

  mapReststate(a, b, from) {
    if (from) {
      console.warn("from", from);
    }
    const mapped = this.mapReststateActions(a, b, from);
    this.registerActions(mapped);
  }
  /**
   *
   * @param {string|array} a {array} of action names | {string} module name; in which case b is the array of action names
   * @param {array} b array of action names
   * @param {string} from for easy debugging
   * @return {object} action names as keys, wrapped reststate actions functions as values
   */
  mapReststateActions(a, b, from) {
    let { namespace, map } = normalisedNamespace(a, b);
    let normalisedMap = normalizeMap(namespace, map);
    const newActions = this.getNewActions(normalisedMap);
    if (empty(newActions)) return [];
    const mapped = this.generateArrayOfMappedActions(newActions);
    return mapped;
  }

  queryKey() {
    const key =
      this.$store.state.submissions.forms[this.$store.state.submissions.path]
        .settings.queryKey;
    // console.log('queryKey', key);
    return key;
  }
  queryMatchesKey() {
    return this.queryMatches(this.queryKey());
  }

  queryMatches(key) {
    const route = this.$router.history.current;
    const keys = Object.keys(route.query);
    return Boolean(keys.includes(key));
  }

  queryValue(key) {
    const route = this.$router.history.current;
    return route.query[key];
  }

  registerActions(actions) {
    for (const [key, fn] of Object.entries(actions)) {
      this.actions[key] = fn;
    }
  }
  /**
   * Given a store name and action name will register the action
   * and return the registered action key
   * @param {string} storeName
   * @param {string} actionName
   * @returns {string} registered action key
   */
  registerAction(storeName, actionName) {
    const funcName = `${storeName}/${actionName}`;
    const func = this.actions[funcName];
    if (typeof func !== `function`) {
      this.mapReststate(
        [funcName],
        undefined
        // "registerAction"
      );
    }
    return funcName;
  }

  /**
   * @todo: unfinished
   * Will check if reated records are cached and return it if so,
   * if not, it will attempt to fetch it and then return it.
   * Problem is how to tell the difference between "there aren't any available records anywhere"
   * and "there are but I need to fetch them from the server" without getting stuck in a loop
   * @param {string} relationship
   * @param {mixed} parent object
   * @returns
   */
  async relatedDammit(storeName, parent, relationship) {
    // console.log('storeName', storeName);

    let options = relationship
      ? {
        parent,
        relationship,
      }
      : {
        parent,
      };
    let related = this.$store.getters[`${storeName}/related`](options);
    if (empty(related)) await this.dammit(storeName, "loadRelated", options);
    related = this.$store.getters[`${storeName}/related`](options);
    return related;
  }

  register(a, b, from) {
    this.mapReststate(a, b, from);
  }

  async removeAllRelated(entity, storeName) {
    const options = {
      parent: {
        id: entity.id,
        type: entity.type,
      },
    };
    console.log(options);
    await this.dammit(storeName, "removeAllRelated", options);
  }

  async removeRelated(entity, relationshipName, relatedIds) {
    const params = {
      parent: {
        id: entity.id,
        type: entity.type,
      },
      data: relatedIds,
    };
    await this.dammit(relationshipName, "removeRelated", params);
  }

  /**
   * Add a set of related records to an entity
   * @param {object} entity (i.e. the reststate parent, which is to say the first part of the JSONAPI URL)
   * @param {object|string} association
   * @param {array|object} relationships reststate ojects(s)
   */
  async addRelated(entity, association, relationships, from = "unknown") {
    if (empty(entity)) {
      throw Error('serviceLayer.addRelated requires first argument to be a valid entity: ', entity);
    }
    if (typeof association === "string") {
      // console.log("entity", entity);

      association = this.$store.getters["schemas/getAssociationByName"](
        entity.type,
        association
      );
    }
    // console.log('addRelated association', association);

    const creatorFuncName = `${lcfirst(association.name)}/addRelated`,
      creatorFunc = this.actions[creatorFuncName],
      relationship = getRelationshipNameFromAssociation(association),

      readRelationship = getAssociationTargetTable(association),
      data = relationships.map((o) => {
        return { type: lcfirst(association.name), id: o.id };
      }),
      options = {
        /**
         * don't use the parent entity wholesale because
         * it was made reactive by vuex and no longer has
         * enumerable properties, which means that it can't
         * be cloned and thus will get broken by qMessage
        */
        parent: {
          id: entity.id,
          type: entity.type,
        },
        relationship,
        readRelationship,
        data,
      };


    if (!_.isFunction(creatorFunc)) {
      this.mapReststate([creatorFuncName], undefined, "addRelated");
    }
    // console.log("addRelated options", options);
    console.debug("📘 >>> addRelated addRelated: ", creatorFuncName, options)
    console.debug('relationshipsToAdd', relationships);
    console.debug('relationship', relationship);
    console.debug('entity.type', entity.type);
    console.debug('association.name', association.name);
    // console.log('readRelationship', readRelationship );
    // console.log('data', data );
    await this.toto(creatorFuncName, options);
    await this.totoDammit(`${lcfirst(association.name)}`, "loadRelated", {
      parent: {
        id: entity.id,
        type: entity.type,
      },
      relationship,
    });
  }

  /**
   * Given an entity, association, and a set of relationship id's,
   * this will replace existing related records.
   * @param {object} entity parent - i.e. first part of the URL
   * @param {object} association
   * @param {array|object} relationships array for multiple, object for single
   * @returns {promise}
   */
  async setRelated(entity, association, relationships) {
    const type = lcfirst(association.name),
      options = {
        parent: {
          id: entity.id,
          type: entity.type,
        },
        relationship: getRelationshipNameFromAssociation(association),
      };

    options.toOne = this.$store.getters["schemas/isToOneAssociation"](
      entity.type,
      association
    );

    //- @why: if relationships are passed in as empty array or empty object then remove all relationships
    if (empty(relationships)) {
      // console.log("case removeAllRelated");
      await this.totoDammit(type, "removeAllRelated", options);
      return
    }

    //- otherwise, format the data for the request and do request
    if (_.isArray(relationships)) {
      //- toOne
      if (options.toOne) {
        options.data = {
          id: relationships[0].id,
          type: relationships[0].type,
        };
      } else {
        //- multiple related
        options.data = relationships.map((o) => {
          return { id: o.id, type: type };
        });
      }
    } else {
      //- single related
      options.data = { id: relationships.id, type: type };
    }
    await this.totoDammit(type, "setRelated", options);
  }

  async updateEntityAttributes({ attributes, id, type }) {
    const data = {
      attributes,
      id,
      type,
    },
      action = `${type}/update`;
    this.registerAction(type, "update");
    // console.log("updateEntityAttributes arguments", arguments);
    // console.log("updateEntityAttributes data", data);
    // console.log("updateEntityAttributes action", action);
    await this.actions[action](data);
  }

  async updateAssociationAttributes(association, formEntities) {
    // console.log("updateAssociationAttributes for", association.name);
    // console.log("updateAssociationAttributes formEntities", formEntities);
    let isDirty = false;
    for (let [eidx, formEntity] of formEntities.entries()) {
      // console.log("updateAssociationAttributes formEntity", formEntity);
      const isDirtyAssociationAttributesOptions = {
        schema: lcfirst(association.className),
        entity: formEntity.id,
        attributes: formEntity?.attributes,
        // from: "serviceLayer::updateAssociationAttributes"
      };
      // console.log(
      //   "isDirtyAssociationAttributesOptions",
      //   isDirtyAssociationAttributesOptions
      // );
      isDirty = this.$store.getters[`schemas/isDirtyAttributes`](
        isDirtyAssociationAttributesOptions
      );
      // console.log("isDirty", isDirty);
      if (isDirty) {
        // @todo: standardize with this.getTarget
        const actionName = lcfirst(association.name);
        const actionOptions = {
          attributes: formEntity.attributes,
          id: formEntity.id,
          type: formEntity.type,
        };
        // console.log("actionName", actionName);
        // console.log("actionOptions", actionOptions);
        console.log('updateAssociationAttributes actionName, actionOptions', actionName, actionOptions);
        await this.totoDammit(actionName, "update", actionOptions);
      }
    }
  }

  /**
   * Filter the list of associations for a schema by a string of association names
   * @param {string} schema schema to get associations from
   * @param {string} limitAssociations list of association names to limit the list to
   * @returns {array}
   */
  limitedAssociations(schema, limitAssociations) {
    // console.log(
    //   "schema associations prior to limitation",
    //   this.$store.getters["schemas/associations"](schema).map((x) => x.name)
    // );
    const associations =
      limitAssociations === false
        ? this.$store.getters["schemas/associations"](schema)
        : this.$store.getters["schemas/associations"](schema).filter((x) =>
          limitAssociations.includes(x.name)
        );
    return associations;
  }

  /**
   * Iterates over associations for a given entity, limited to an optionally provided list of associations.
   * It will assume data is to be found on `related` node of entity unless provided.
   * @param {string} schema
   * @param {object} options
   * - @param {object} entity may include related
   * - @param {object?} relatedData will default to entity.related
   * - @param {array?} limitAssociations will default to schema/associations getter
   */
  async updateEntityAssociations(schema, options) {
    const {
      entity,
      relatedData = false,
      limitAssociations = false,
      from = "unknown",
    } = options;
    if (empty(entity)) {
      console.error("updateEntityAssociations - empty entity provided", entity);
    }
    if (empty(entity.related) && empty(relatedData)) {
      console.error(
        "updateEntityAssociations - could not update as there are no dirty related data on entity " +
        entity.type
      );
      return;
    }
    const associations = this.limitedAssociations(schema, limitAssociations);
    // console.log("updateEntityAssociations from", from);
    // console.log("updateEntityAssociations schema", schema);
    // console.log("updateEntityAssociations entity", entity);
    // console.log("updateEntityAssociations relatedData submitted", relatedData);
    // console.log(
    //   "updateEntityAssociations associations that will be processed",
    //   associations.map(x => x.name)
    // );
    // console.log(
    //   "updateEntityAssociations limitAssociations",
    //   limitAssociations
    // );

    const related = function (entity) {
      return entity.related;
    };


    for (const [idx, association] of associations.entries()) {
      // console.log("updateEntityAssociations association idx", idx);
      // console.log(
      //   "updateEntityAssociations association.name",
      //   association.name
      // );
      // console.log(
      //   "updateEntityAssociations entity has .related",
      //   !!entity.related
      // );
      // console.log(
      //   "updateEntityAssociations entity cannot be used as source of related data",
      //   !entity.related || !entity.related[association.name]
      // );
      let dataStream;
      if (typeof relatedData === "undefined") {
        console.error(
          "updateEntityAssociations relatedData parameter must be defined for associations to be updated. If the entity already contains this data (via .related) then the value for relatedData should be set to `false`"
        );
        return;
      }
      // if relatedData is a string, then try to find the data using that as a path
      else if (typeof relatedData === "string") {
        // console.log(
        //   "updateEntityAssociations typeof related = String: ",
        //   relatedData
        // );

        dataStream = `${relatedData}.${association.name}`;
        // console.log("updateEntityAssociations dataStream", dataStream);
      }
      // if relatedData is an object, then get the data from the association node
      else if (typeof relatedData === "object") {
        // console.log(
        //   "updateEntityAssociations typeof related = Object: ",
        //   relatedData
        // );
        // console.log("looking for ", association.name);
        // console.log("in relatedData ", relatedData);
        dataStream = relatedData[association.name];
      }
      // if relatedData is false, then try to find the data from the entity related data
      else {
        if (typeof entity.related === "undefined") {
          console.error(
            "updateEntityAssociations relatedData argument was not provided and the entity does not contain related data either, thus cannot update associations."
          );
          return;
        }
        if (typeof entity.related[association.name] === "undefined") {
          console.error(
            "updateEntityAssociations relatedData argument was not provided and the entity does not contain associated data either, thus cannot update associations."
          );
          return;
        }
        // console.log(
        //   "updateEntityAssociations data that will be processed",
        //   entity.related[association.name]
        // );

        dataStream = strip(entity.related[association.name]);
        // console.log("updateEntityAssociations dataStream", dataStream);
      }

      // console.log("updateEntityAssociations dataStream", dataStream);
      if (empty(dataStream)) {
        // console.log(
        //   "updateEntityAssociations - no data to send for association. (Will continue with other associations) ",
        //   association.name
        // );
        continue;
      }
      await this.updateEntityAssociation(
        schema,
        entity,
        association,
        dataStream
      );
    }
  }

  /**
   * this does more than what it sounds like, it POSTS new associated entities.
   * So the name is misleading. It will handle `added` and `edited` records differently
   * @param {string} schema of the entity
   * @param {object} entity parent
   * @param {object|string} association association that defines the relationships
   * @param {string|object} pathOrData path to node on submissions store, or the new data itself by other means as an object
   */
  async updateEntityAssociation(schema, entity, association, pathOrData) {

    // console.debug('📘 : AAA updateEntityAssociation schema', schema, association.name);
    // console.debug('associaion', association);

    const debug = false // 'Clients';

    //- if there is no association, then there are no possible relationships to process.
    if (empty(association)) return;
    if (_.isString(association)) {
      association = this.$store.getters["schemas/getAssociationByName"](
        schema,
        association
      );
    }
    const splitRelationships = this.$store.getters[
      "schemas/splitRelationships"
    ]({ schema, entity, association, pathOrData
      // , debug: "Clients" 
    });
    // console.log('association.name', association.name);
    let {
      added,
      edited,
      removed,
      emptyOldRelationships,
      newRelationships,
      oldRelationships,
    } = splitRelationships;

    //- @why: test isDirty (without using isDirtyAssociations as that runs splitRelationships again and also only works for the Prime entity, which Documents is not)
    if (relationshipsAreEqual(splitRelationships, debug)) {
      // console.debug(
      //   `📙 serviceLayer.updateEntityAssociation: relationships are equal for association: ${association.name}, returning`
      // );
      return;
    } else {
      // console.debug(
      //   `📘 serviceLayer.updateEntityAssociation: relationships are NOT equal for association: ${association.name}, processing update...`
      // );
    }

    //- create POST requests for any new records
    await this.insertNewRelationships(association, added);

    if (
      this.$store.getters["schemas/isToOneAssociation"](schema, association)
    ) {

      //- for -to-one association, create PATCH (as required by JSONAPI spec)
      if (!empty(edited)) {
        // console.debug(
        //   '📘 for -to-one association, create PATCH (as required by JSONAPI spec)'
        // );
        await this.setRelated(entity, association, edited);
      }
    } else {

      //- create DELETE requests for any relationships that were removed (except for many to one, in which case deletion isn't necessary, as it will just replace)

      //- if all are to be removed, then remove all
      if (!empty(removed) && removed.length === oldRelationships.length) {
        // console.debug( '📘 remove all');
        await this.removeAllRelated(entity, lcfirst(association.name));

        // also mutate the reverse relationship
        const options = {
          parent: {
            id: entity.id,
            type: entity.type
          },
          relationship: lcfirst(association.name)
        };
        // console.log('removeRelated options', { params: options });
        this.$store.commit(`${lcfirst(association.className)}/REMOVE_ALL_RELATED`, { params: options });

      }

      // if(association.name === "Clients") {
      //   alert('Clients is not ToOneAssociation, stopping');
      //   return;
      // }

      //- if only some are to be removed, then remove those
      if (!empty(removed) && removed.length !== oldRelationships.length) {
        // console.debug( '📘 remove related');
        await this.removeRelated(
          entity,
          lcfirst(association.name),
          removed.map((x) => {
            return { type: x.type, id: x.id };
          })
        );

        // also mutate the reverse relationship
        const relationshipName = getRelationshipNameFromAssociation(association);
        if(relationshipName !== association.targetTable) {
          const params = {
            parent: {
              id: entity.id,
              type: entity.type
            },
            relationship: relationshipName,
          };
          const relatedIds = removed.map((x) => {
            return { type: x.type, id: x.id };
          });
          this.$store.commit(`${association.targetTable}/REMOVE_RELATED`, { relatedIds, params });
        }
      }

      //- for other associations, create POST request (as required by the JSONAPI spec)
      const relationshipsToAdd = [...added, ...edited];

      //- Add the new related entity to it's model's table
      if (relationshipsToAdd.length) {
        // console.debug( '📘 remove related');
        await this.addRelated(
          entity,
          association,
          relationshipsToAdd,
          "serviceLayer updateEntityAssociation"
        );

        //- 
        for (const e of relationshipsToAdd) {
          if (typeof e.related === "object") {
            for (const rel in e.related) {
              if (empty(rel)) continue;
              if (!e.related[rel]?.type) continue;
              await this.updateEntityAssociation(
                e.type,
                e,
                ucfirst(e.related[rel].type),
                e.related[rel]
              );
            }
          }
        }
      }
    }
  }

  arrayDammit(arr) {
    return Array.isArray(arr) ? arr : [arr];
  }

  isFormNodeEmpty(data) {
    if (_.isArray(data)) {
      return empty(
        data.reduce((collector, val) => {
          if (!empty(JSON.parse(JSON.stringify(val)))) {
            collector.push(val);
          }
          return collector;
        }, [])
      );
    } else if (_.isObject(data)) {
      return empty(JSON.parse(JSON.stringify(data)));
    } else {
      return empty(data);
    }
  }

  getFormNodeData(data) {
    return strip(data);
  }

  async loadRelatedForCompliance(compliance, cigx) {
    await this.loadRelationships(this.compliance, ["People", "Authorisations"]);
    let People = this.$store.getters["people/related"]({
      parent: { id: compliance.id, type: compliance.type },
    });
    let Notarial = this.$store.getters["submissions/entity"];
    let Authorisations = this.$store.getters["authorisations/related"]({
      parent: { id: compliance.id, type: compliance.type },
    });

    const currentFormData = this.$store.getters["submissions/currentFormData"];

    if (empty(People) && !empty(currentFormData.related.People))
      People = Array.isArray(currentFormData.related.People)
        ? [...currentFormData.related.People]
        : currentFormData.related.People;

    if (empty(Authorisations) && !empty(currentFormData.related.Authorisations))
      Authorisations = Array.isArray(currentFormData.related.Authorisations)
        ? [...currentFormData.related.Authorisations]
        : currentFormData.related.Authorisations;

    this.setDocumentAssociationValue(cigx, "People", People);
    this.setDocumentAssociationValue(cigx, "Notarial", Notarial);
    this.setDocumentAssociationValue(cigx, "Authorisations", Authorisations);
  }

  async loadRelatedForDocuments(limitAssociations) {
    const documents = this.$store.getters["submissions/documents"];
    // console.log("documents", documents);
    // console.log("documents entries", documents.entries());
    if (empty(documents)) return;
    for (let [docIdx, document] of documents.entries()) {
      // console.log("document", document);
      // console.log("docIdx", docIdx);
      await this.loadRelatedForDocument(document, docIdx, limitAssociations);
    }
  }

  /**
   * Loads the relationships for a given document and writes those to the form store
   * @param {object} document
   * @param {number} docIdx
   * @param {array} limitAssociations
   */
  async loadRelatedForDocument(document, docIdx, limitAssociations) {
    //- load from the DB
    await this.loadRelationships(document, limitAssociations);

    //- get related from reststate store
    let Clients = this.$store.getters["clients/related"]({
      parent: {
        id: document.id,
        type: document.type,
      },
    });
    let Representatives = this.$store.getters["representatives/related"]({
      parent: {
        id: document.id,
        type: document.type,
      },
    });
    let Organisations = this.$store.getters["organisations/related"]({
      parent: {
        id: document.id,
        type: document.type,
      },
    });
    let Files = this.$store.getters["files/related"]({
      parent: {
        id: document.id,
        type: "documents",
      },
      relationship: "file",
    });

    const currentFormData = this.$store.getters["submissions/currentFormData"];
    switch (this.$store.getters["submissions/who"]) {
      case ENQUIRY_WHO.ind: // Individual
        //- if empty DB clients, set default from Notarial
        if (empty(Clients) && !empty(currentFormData.related.Clients))
          Clients = Array.isArray(currentFormData.related.Clients)
            ? [...currentFormData.related.Clients]
            : currentFormData.related.Clients;
        break;
      case ENQUIRY_WHO.rep: // Representative of Individual
        //- if empty DB Clients, set default from Notarial
        if (empty(Clients) && !empty(currentFormData.related.Clients))
          Clients = Array.isArray(currentFormData.related.Clients)
            ? [...currentFormData.related.Clients]
            : currentFormData.related.Clients;
        //- if empty DB Representatives, set default from Notarial
        if (
          empty(Representatives) &&
          !empty(currentFormData.related.Representatives)
        )
          Representatives = Array.isArray(
            currentFormData.related.Representatives
          )
            ? [...currentFormData.related.Representatives]
            : currentFormData.related.Representatives;
        break;
      case ENQUIRY_WHO.org: // Representative of Organisation
        //- if empty DB Representatives, set default from Notarial
        if (
          empty(Representatives) &&
          !empty(currentFormData.related.Representatives)
        )
          Representatives = Array.isArray(
            currentFormData.related.Representatives
          )
            ? [...currentFormData.related.Representatives]
            : currentFormData.related.Representatives;

        //- if empty DB Organisation, set default from Notarial
        if (
          empty(Organisations) &&
          !empty(currentFormData.related.Organisations)
        )
          Organisations = Array.isArray(currentFormData.related.Organisations)
            ? [...currentFormData.related.Organisations]
            : currentFormData.related.Organisations;
        break;
    }
    // console.log('Clients', Clients);
    this.setDocumentAssociationValue(docIdx, "Clients", Clients);
    this.setDocumentAssociationValue(
      docIdx,
      "Representatives",
      Representatives
    );
    this.setDocumentAssociationValue(docIdx, "Organisations", Organisations);
    this.setDocumentAssociationValue(docIdx, "Files", Files);
  }

  setDocumentAssociationValue(docIdx, associationName, value) {
    // console.log("args", arguments);
    const documentRelatedPath = `${this.$store.getters["submissions/formDataPath"]}.related.Documents[${docIdx}].related`;
    this.setRelatedAssociationValue(
      documentRelatedPath,
      docIdx,
      associationName,
      value
    );
  }

  setRelatedAssociationValue(path, idx, associationName, value) {
    //- first write the related node if it doesn't exist
    // console.log('setRelatedAssociationValue associationName', associationName)
    // console.log('setRelatedAssociationValue path', path)

    const related = this.$store.getters["submissions/getField"](path);
    // console.log("related", related);
    if (empty(related)) {
      this.$store.commit("submissions/updateField", {
        path,
        value: {},
      });
    }
    let associationPath = `${path}.${associationName}`;
    // console.log("associationPath", associationPath);
    // console.log("path", path);
    this.$store.commit("submissions/updateField", {
      path: associationPath,
      value,
    });
  }
}

export default ServiceLayer;
